/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

// Components
import { SEO, ParseContent } from 'components/shared'
import Layout from 'components/layout/Layout'
import Breadcrumb from 'components/elements/BreadCrumb'
import { Benefit } from 'components/elements'
import Plaatjie from '@ubo/plaatjie'

const BannerImage = styled(Plaatjie)`
  height: 500px;

  @media (min-width: 992px){
    & img {
      object-fit: fill !important;
    } 
  }

  @media (max-width: 991px){
    height: 680px;
  }
`

const PageTemplate = ({
  data: {
    page: { path, title, yoast_meta: yoast, acf },
  },
  pageContext
}) => {

  return (
    <Layout hasNewsletter={false}>
      <SEO
        yoast={yoast}
        path={path}
        image={{
          url:
            acf.banner.image.localFile &&
            acf.banner.image.localFile.childImageSharp.openGraph.src,
        }}
      />

      <Hero>
        <BannerImage image={acf.banner.image} />
        <HeroContent className="container d-flex flex-wrap justify-content-end">
          <HeroContentTitle className="col-lg-7 pr-lg-5 mb-5 d-flex align-items-center">
            <div className="pr-lg-5">
              <ParseContent content={acf.banner.description} />
              <Breadcrumb data={title} className="py-0" pageContext={pageContext} />
            </div>
          </HeroContentTitle>
          <div className="col-lg-5">
            <Plaatjie
              image={acf.cta.image}
              alt={acf.cta.image.title}
              style={{
                borderRadius: 20,
                width: '100%',
                height: 300,
                objectFit: 'cover',
                boxShadow: '0 0 4px rgba(0,0,0,0.5)',
              }}
            />
          </div>
        </HeroContent>
      </Hero>

      <MainContent className="container mt-4 mt-lg-0">
        <div className="row">
          <div className="col-lg-7 mt-5 mt-lg-3">
            <Content>
              <ParseContent
                content={acf.meta.description_top}
              />
            </Content>
          </div>

          <div className="col-lg-5 d-flex flex-column justify-content-start">
            {acf.cta.description && (
              <Outsource content={acf.cta.description} />
            )}

            {acf.cta_second.description && (
              <Outsource content={acf.cta_second.description} className="mt-5" />
            )}

            {acf.cta_third.description && (
              <Outsource content={acf.cta_third.description} className="mt-5" />
            )}

          </div>

          <div className="col-lg-12 mb-5">
            <Benefit fields={acf.meta} />
          </div>

          {acf.knowitem && (
            <KnowItem id={acf.knowitem} />
          )}
        </div>
      </MainContent>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      wordpress_id
      title
      path

      acf {
        banner {
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 2000, quality: 100)
                openGraph: fluid(maxWidth: 1500, toFormat: JPG) {
                  src
                }
              }
            }
          }
          description
        }

        meta {
          description_top
          block {
            title
            usps {
              description
            }
          }
          background {
            localFile {
              publicURL
              childImageSharp {
                fluid(toFormat: WEBP, maxWidth: 2000, quality:100) {
                  src
                }
              }
            }
          }
        }

        cta {
          image {
            title
            localFile {
              childImageSharp {
                gatsbyImageData(width: 700, quality: 100)
              }
            }
          }
          description
        }

        cta_second {
          description
        }

        cta_third {
          description
        }
        knowitem
      }

      yoast_meta {
        name
        content
        property
      }
    }
  }
`

export default PageTemplate

const Hero = styled.section`
  position: relative;
  height: 500px;

  @media (max-width: 991px){
    height: 680px;
  }
`

const HeroContent = styled.div`
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  color: white;

  h3 {
    font-size: ${(props) => props.theme.font.size.xxl};
    font-family: ${(props) => props.theme.font.family.bold};
    line-height: ${(props) => props.theme.font.size.xxxl};
  }
`
const HeroContentTitle = styled.div`
  padding-left: 80px;
  @media screen and (max-width: 992px) {
    padding-left: 0;
  }
`
const MainContent = styled.div`
  @media (min-width: 992px) {
    padding: 0 80px;
  }
`

const Content = styled.div`
  letter-spacing: 0.7px;
  color: black;
  h5 {
    font-size: ${(props) => props.theme.font.size.m};
    font-family: ${(props) => props.theme.font.family.bold};
    line-height: ${(props) => props.theme.font.size.l};
    color: ${(props) => props.theme.color.text.secondary};
  }
`
const Outsource = styled(ParseContent)`
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  background: white;
  padding: 35px;
  height: fit-content;
  position: relative;
  
  & h3 {
    font-size: ${props => props.theme.font.size.l};
    line-height: ${props => props.theme.font.size.l};
  }

  @media (min-width: 992px) {
    margin: -60px 0px 0 80px;
  }

  @media screen and (max-width: 991px) {
    margin: 20px 0 0 0;
  }
`
